<!-- 图片查看器弹窗 -->
<template>
    <transition name="fade_leave_opacity">
      <div class="mask" v-if="isShowViewer">

        <!-- 背景，因做渐入效果，单独使用元素 -->
        <div class="config_bk" @click="closeViewer"></div>

        <div class="preview-box">
            <!-- 标题区开始 -->
            <div class="title">
                <div class="close-btn flex-center">
                    <i class="el-icon-circle-close" @click="closeViewer"></i>
                </div>
                <div class="text">查看大图({{currentIndex + 1}}/{{this.urlList.length}})</div>

            </div>
            <!-- 标题区结束 -->
            <!-- 图片区开始 -->
            <div class="img-box">
                <!-- 左箭头 -->
                <div class="left-arrow" v-if="urlList.length > 1">
                    <el-button class="icon" size="mini" @click="leftClick" :disabled="currentIndex === 0">
                        <i class="iconfont iconjinruzuo"></i>
                    </el-button>
                </div>
                <!-- 切换图片 -->
                  <div class="trans-img" :class="{'no-arrow' : urlList.length <= 1}">
                    <!-- <transition-group  name="fade">                   -->
                        <el-image
                          v-for="(url, i) in urlList"
                          :key="url+i"   v-show="i === currentIndex"
                          class="trans-img_item"
                          :src="currentImg"
                          fit="contain"></el-image>
<!--                    <div class="text"   v-for="(arr, index) in picAddress" :key="index" >{{arr}}</div>-->
                    <!-- </transition-group > -->
                  </div>
                <!-- 右箭头 -->
                <div class="right-arrow" v-if="urlList.length > 1">
                    <el-button class="icon" size="mini" @click="rightClick"  :disabled="currentIndex === urlList.length -1">
                        <i class="iconfont iconjinruyou"></i>
                    </el-button>
                </div>
            </div>
            <!-- 图片区结束 -->

        </div>
      </div>
    </transition>
</template>

<script>
export default {
  props: {
    zIndex: {
      type: Number,
      default: 2000
    },
  },
  data () {
    return {
      // 控制查看器的展示与隐藏
      isShowViewer: false,
      // 当前图片索引
      currentIndex: 0,
      // 禁止页面滚动对象
      prevOverflow: null,
      picAddress:'这是地址'

    }
  },
  computed: {
    currentImg () {
      return this.urlList[this.currentIndex]
    },
    currentAdd () {
      return this.picAddress[this.currentIndex]
    },
  },
  methods: {
    // 左箭头点击
    leftClick () {
      if (this.currentIndex !== 0) {
        this.currentIndex--
      }
    },
    // 右箭头点击
    rightClick () {
      if (this.currentIndex !== this.urlList.length - 1) {
        this.currentIndex++
      }
    },
    // 关闭弹窗
    closeViewer () {
      document.body.style.overflow = this.prevOverflow
      this.isShowViewer = false
      this.currentIndex = 0
    },
    // 打开弹窗
    showViewer (list, index,arr) {
      this.picAddress=arr|| []
      this.urlList = list || []
      this.currentIndex = index || 0
      this.isShowViewer = true
    }
  },
  watch: {
    prevOverflow (value) {
      if (!value) return
      // 禁止页面滚动
      this.prevOverflow = document.body.style.overflow
      document.body.style.overflow = 'hidden'
    }
  }
}

</script>
<style lang='less' scoped>
.mask{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .config_bk {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: rgba(0,0,0,0.5);
      backdrop-filter: blur(10px);
      animation: fade_enter_opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      @keyframes fade_enter_opacity {
        from { opacity: 0; }
        to { opacity: 1; }
      }
    }
    .preview-box{
      border-radius: 8px;
      animation: fade_enter_top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      @keyframes fade_enter_top {
        from { transform: translateY(-15px); }
        to { transform: translateY(0); }
      }
      .title{
          padding-left: 24px;
          height: 54px;
          line-height: 54px;
          position: relative;
          border-radius: 8px 8px 0px 0px;
          .close-btn {
              cursor: pointer;
              position: absolute;
              right: 0;
              color: #fff;
              width: 44px;
              height: 44px;
              font-size: 36px;
          }
          .text {
            color: #fff;
          }
      }
      .img-box{
          padding: 24px;
          border-radius: 0px 0px 8px 8px;
          display: flex;
          .trans-img{
              margin: 0 24px;
              width: 65vw;
              height: 70vh;
              position: relative;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              .trans-img_item {
                height: 100%;
                width: 100%;
              }
          }
          .left-arrow,.right-arrow{
              cursor: pointer;
              width: 52px;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 52px;
                  height: 52px;
                  i {
                    font-size: 30px;
                  }
              }
          }
      }
      .no-arrow{
        margin: 0 !important;
      }
    }
}

    .fade_leave_opacity-leave-active {
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .fade_leave_opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
</style>
